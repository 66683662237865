import { useState, useEffect } from 'react';

const PasswordInput = ({ passwordKey, onCorrect }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (password === passwordKey) {
      onCorrect();
    }
  }, [password, passwordKey, onCorrect]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full p-4  flex justify-center items-center gap-4">
      <input
        type={showPassword ? 'text' : 'password'}
        value={password}
        placeholder="Enter password"
        onChange={(e) => setPassword(e.target.value)}
        className="bg-[#F3F6F6] dark:bg-[#111111] dark:text-white  px-4 py-2 rounded-md"
      />
    </div>
  );
};

export default PasswordInput;
