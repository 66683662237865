import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import Modal from 'react-modal';
import UseData from '../../Hooks/UseData';
import Footer from '../../Share/Footer';
import { FiUser, FiCode, FiFilePlus, FiExternalLink } from 'react-icons/fi';
import { BsXCircle } from 'react-icons/bs';
import { useEffect } from 'react';
import PageTitle from '../../Share/PageTitle';
import ReactPlayer from 'react-player';
import PasswordInput from './PasswordInput';
import SliderCommon from '../../Share/SliderCommon';
import ClientsGallery from '../../Share/ClientsGallery';

Modal.setAppElement('#root');

const CommonPortfilo = ({ condition, items, passwordProtected = false }) => {
  const {
    local,
    handleData,
    data,
    handleModelData,
    isOpen,
    setIsOpen,
    singleData,
    isPassCorrect,
    setIsPassCorrect,
  } = UseData();

  const [test, setTest] = useState('All');

  const handleSearch = (text) => {
    handleData(text);
    setTest(text);
  };

  useEffect(() => {
    setTest('All');
    handleData('All');

    if (!passwordProtected) {
      setIsPassCorrect(true);
    }
  }, []);

  const handleModle = (id) => {
    handleModelData(id);
  };

  const item = condition ? 3 : 2;

  const breakpointColumnsObj = {
    default: item,
    1100: 2,
    500: 1,
  };

  return (
    <>
      <PageTitle title="Portfolio"></PageTitle>
      {/* End pagetitle */}

      <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          {isPassCorrect && (
            <div className="px-2 sm:px-5 md:px-10 lg:px-14 ">
              {/* Slick Slider call here  */}
              <ClientsGallery />
            </div>
          )}
          <div
            className={`px-2 sm:px-5 md:px-10 lg:px-14 py-0 ${
              isPassCorrect ? '' : 'pt-12'
            }`}
          >
            <h2 className="after-effect  after:left-48 lg:mt-0 ">Portfolio</h2>
          </div>

          {!isPassCorrect ? (
            <div className="w-full items-center justify-center">
              <PasswordInput
                passwordKey={process.env.REACT_APP_WORKS_PASSWORD}
                onCorrect={() => setIsPassCorrect(true)}
              />
            </div>
          ) : (
            <div className="container mb-8 px-4 sm:px-5 md:px-10 lg:px-[60px]">
              <div className="pb-12 pt-8">
                {/* Portfilo filter tab start */}

                <ul
                  className={`${
                    item === 2 ? 'mt-[30px] ' : 'mt-[40px]'
                  } flex w-full justify-start md:justify-end  flex-wrap   font-medium`}
                >
                  <li
                    className={`${
                      test === 'All' ? 'text-[#FA5252]' : 'fillter-btn '
                    } mr-4 md:mx-4`}
                    onClick={() => handleSearch('All')}
                  >
                    All
                  </li>
                  <li
                    className={`${
                      test === 'Graphic Design'
                        ? 'text-[#FA5252]'
                        : 'fillter-btn'
                    } mr-4 md:mx-4`}
                    onClick={() => handleSearch('Graphic Design')}
                  >
                    Graphic Design
                  </li>
                  <li
                    className={`${
                      test === 'Content Creation'
                        ? 'text-[#FA5252]'
                        : 'fillter-btn'
                    } mr-4 md:mx-4`}
                    onClick={() => handleSearch('Content Creation')}
                  >
                    Content Creation
                  </li>
                  <li
                    className={`${
                      test === 'Blog' ? 'text-[#FA5252]' : 'fillter-btn'
                    } mr-4 md:mx-4`}
                    onClick={() => handleSearch('Blog')}
                  >
                    Blog
                  </li>
                </ul>

                {/* Portfilo filter tab start */}
              </div>

              {/* Portfolio items start */}

              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {data.slice(0, items).map((item) => (
                  <div
                    style={{
                      background: `${
                        local === 'dark' ? 'transparent' : item?.bg
                      }`,
                    }}
                    className="rounded-lg p-6 dark:border-[2px] border-[#212425]"
                    key={item.id}
                  >
                    <div className=" overflow-hidden rounded-lg">
                      <img
                        onClick={() => handleModle(item.id)}
                        className="w-full    cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 rounded-lg h-auto "
                        src={item?.imgSmall}
                        alt=""
                      />
                    </div>
                    <span className="pt-5 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]">
                      {item?.tag}
                    </span>
                    <h2
                      onClick={() => handleModle(item.id)}
                      className="font-medium cursor-pointer text-xl duration-300 transition hover:text-[#FA5252] dark:hover:text-[#FA5252] dark:text-white mt-2"
                    >
                      {item?.title}
                    </h2>
                  </div>
                ))}
              </Masonry>

              {/* Portfolio items end */}

              {/* Portfolio modal start */}

              <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                className=" outline-none flex items-center  p-4 md:p-8  rounded-2xl my-8"
              >
                <div className=" w-full md:w-10/12 flex items-center   lg:w-[850px] bg-white dark:bg-[#323232] mx-auto rounded-xl p-4 md:p-8 absolute left-1/2 top-1/2 transform -translate-x-[50%] -translate-y-[50%] shadow-lg ">
                  <div className=" overflow-y-scroll max-h-[80vh] no-scrollbar ">
                    {/* close button */}
                    <BsXCircle
                      onClick={() => setIsOpen(false)}
                      className="text-7xl cursor-pointer  absolute right-2 -top-12 md:-right-10 md:-top-6 z-50  text-white transition transform hover:rotate-45 duration-300 ease-in-out "
                    />
                    <h2 className="text-[#ef4060] dark:hover:text-[#FA5252] text-4xl text-center font-bold">
                      {singleData.tag} Project
                    </h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 my-6">
                      <div className="space-y-2">
                        <p className="dark:text-white flex items-center mt-2 lg:mt-0 text-[15px]  sm:text-lg ">
                          <FiUser className="text-lg mr-2 hidden sm:block" />
                          Client :&nbsp;
                          <span className="font-medium ">
                            {singleData?.client}
                          </span>
                        </p>
                        <p className="dark:text-white flex items-center text-[15px] sm:text-lg ">
                          <FiExternalLink className="text-lg mr-2 hidden sm:block" />
                          Preview :&nbsp;
                          <span className="font-medium transition-all duration-300 ease-in-out hover:text-[#ef4060] ">
                            <a
                              href={singleData?.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {singleData?.linkText}
                            </a>
                          </span>
                        </p>
                      </div>
                      <div className="space-y-2">
                        <p className="dark:text-white flex items-center text-[15px]  sm:text-lg ">
                          <FiCode className="text-lg mr-2 hidden sm:block " />
                          Tools :&nbsp;
                          <span className="font-medium ">
                            {singleData?.tools}
                          </span>
                        </p>
                      </div>
                    </div>
                    <p className="dark:text-white  text-2line font-normal text-[15px] sm:text-sm  ">
                      {singleData?.description}
                    </p>

                    <img
                      className={
                        singleData.img !== ''
                          ? 'w-full md:h-[450px]  h-auto object-cover rounded-xl mt-6'
                          : 'hidden'
                      }
                      src={singleData.img}
                      alt=""
                    />

                    <div
                      className={
                        singleData.vid !== ''
                          ? 'overflow-hidden w-full md:h-[450px]  h-auto object-cover rounded-xl mt-6'
                          : 'hidden'
                      }
                    >
                      <ReactPlayer
                        className="react-player"
                        url={singleData.vid}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </div>
              </Modal>

              {/* Portfolio modal end */}
            </div>
          )}
          {/* End Portfolio */}

          {/* Common Footer call here*/}
          <Footer condition={false} bg={'#FFFF'} />
        </div>
      </section>
    </>
  );
};

export default CommonPortfilo;
