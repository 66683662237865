import React from 'react';
import CommonPortfilo from './CommonPortfilo';
import { useState } from 'react';

const Portfolio = () => {
  return (
    <div>
      <CommonPortfilo items={10} condition={false} passwordProtected={true} />
    </div>
  );
};

export default Portfolio;
