import React from 'react';
import UseData from '../../Hooks/UseData';
import Footer from '../../Share/Footer';
import '../../Share/Style.css';

import AboutCard from './AboutCard';
import HomeCard from '../../Share/HomeCard';
import PageTitle from '../../Share/PageTitle';

const About = () => {
  const { local, professionalTitlesArray } = UseData();

  return (
    <section>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect after:left-52">About Me</h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite  dark:text-color-910 leading-7">
                    A creative, passionate and innovative social media fanatic
                    with 9+ years of professional experience with driving brands
                    forward and achieving substantial growth in the digital
                    space.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    With a personal first hand look at the influencer and social
                    media world, I am well versed in the strategies and inner
                    workings of how to successfully achieve desired results for
                    any brand or client that comes my way.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Armed with a constantly updating knowledge bank on all
                    things social, I am equipped with what it takes to leverage
                    brand awareness on a plethora of platforms. Achieving
                    success in the digital world requires the relentless drive
                    to stay updated with relevant content, all while engaging
                    and creating stimulating yet genuine experiences in order to
                    build a loyal relationship with the audience.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    In my role, I thrive on creative problem solving and come up
                    with effective and innovative ideas for those I work with.
                    Make sure to review my Social Media Portfolio for examples
                    of my work, and to learn about clients I’ve worked with in
                    the past. I look forward to helping your business grow.
                  </p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5">
              What I do!
            </h3>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {professionalTitlesArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}

          {/* Common Footer call here */}
          <Footer bg={'#FFFF'} />
        </div>
      </div>
    </section>
  );
};

export default About;
