import React from 'react';
import Slider from 'react-slick';
import UseData from '../Hooks/UseData';

const ClientsGallery = () => {
  const { sliderImg } = UseData();

  return (
    <div className="bg-white dark:bg-transparent max-w-full h-auto py-12 rounded-xl">
      <h2 className="after-effect  after:left-48  lg:mt-0">Clients</h2>
      <div className="flex flex-wrap justify-start items-center gap-4 bg-[#f1fff5] dark:bg-[#1D1D1D] p-10 rounded-xl mt-12">
        {sliderImg.map((item, i) => (
          <img
            key={i}
            className=" overflow-hidden w-20 rounded-lg"
            src={item}
            alt=""
          />
        ))}
      </div>
    </div>
  );
};

export default ClientsGallery;
